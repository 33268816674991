import React from "react"

const IconInstagram = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 88 88"
      style={{enableBackground: `new 0 0 88 88`}}
      xmlSpace="preserve"
      className="icon icon_social icon__instagram"
    >
      <path
        d="M44,25.1c6.1,0,6.9,0,9.3,0.1c1.5,0,2.9,0.3,4.3,0.8c2,0.8,3.6,2.4,4.4,4.4c0.5,1.4,0.8,2.8,0.8,4.3c0.1,2.4,0.1,3.2,0.1,9.3s0,6.9-0.1,9.3c0,1.5-0.3,2.9-0.8,4.3c-0.8,2-2.4,3.6-4.4,4.4c-1.4,0.5-2.8,0.8-4.3,0.8c-2.4,0.1-3.2,0.1-9.3,0.1s-6.9,0-9.3-0.1c-1.5,0-2.9-0.3-4.3-0.8c-2-0.8-3.6-2.4-4.4-4.4c-0.5-1.4-0.8-2.8-0.8-4.3c-0.1-2.4-0.1-3.2-0.1-9.3s0-6.9,0.1-9.3c0-1.5,0.3-2.9,0.8-4.3c0.8-2,2.4-3.6,4.4-4.4c1.4-0.5,2.8-0.8,4.3-0.8C37.1,25.2,37.9,25.1,44,25.1 M44,21c-6.2,0-7,0-9.5,0.1c-1.9,0-3.8,0.4-5.6,1.1c-3.1,1.2-5.5,3.6-6.7,6.7c-0.7,1.8-1,3.7-1.1,5.6C21,37,21,37.8,21,44s0,7,0.1,9.5c0,1.9,0.4,3.8,1.1,5.6c1.2,3.1,3.6,5.5,6.7,6.7c1.8,0.7,3.7,1,5.6,1.1C37,67,37.8,67,44,67s7,0,9.5-0.1c1.9,0,3.8-0.4,5.6-1.1c3.1-1.2,5.5-3.6,6.7-6.7c0.7-1.8,1-3.7,1.1-5.6C67,51,67,50.2,67,44s0-7-0.1-9.5c0-1.9-0.4-3.8-1.1-5.6c-1.2-3.1-3.6-5.5-6.7-6.7c-1.8-0.7-3.7-1-5.6-1.1C51,21,50.2,21,44,21 M44,32.2c-6.5,0-11.8,5.3-11.8,11.8c0,6.5,5.3,11.8,11.8,11.8c6.5,0,11.8-5.3,11.8-11.8C55.8,37.5,50.5,32.2,44,32.2C44,32.2,44,32.2,44,32.2 M44,51.7c-4.2,0-7.7-3.4-7.7-7.7s3.4-7.7,7.7-7.7s7.7,3.4,7.7,7.7l0,0C51.7,48.2,48.2,51.7,44,51.7M59,31.7c0,1.5-1.2,2.8-2.8,2.8c-1.5,0-2.8-1.2-2.8-2.8s1.2-2.8,2.8-2.8l0,0C57.8,29,59,30.2,59,31.7"
      />
    </svg >
  )
}

export default IconInstagram
