import React from "react"
import PropTypes from "prop-types"
import Glide from "@glidejs/glide"
import "../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css"
import "../neo/blocks/types/slider.css"

const SlideContent = ({ children, className, ...props }) => {
    const attributes = {
      ...props,
      className: (className || '') + ' absolute z-10 flex flex-col text-center slide-text-container',
    }
    return (
      <>
        <div className={"slide-text-container-background "} ></div>
        <div {...attributes} >
          {children}
        </div>
      </>
    );
}

SlideContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

const SlideMedia = ({ className, src, type, settings, alt, screenSize, ...props }) => {
    const attributes = {
      ...props,
      ...settings,
      muted: settings.muted || true,
      ...(settings.autoplay && {
        autoplay: false,
        autoPlay: settings.autoplay,
      }),
      className: (className || '') + ' ' + screenSize,
    }

    if(type.includes('video')) {
      return (
        <video {...attributes} >
            <source src={src} type={type} />
            {alt && <p>{alt}</p>}
        </video>
      );
    }
    
    if(type.includes('image')) {
      return (
        <img src={src}  alt={alt}  type={type} {...attributes} />
      );
    }

    return '';
}

SlideMedia.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string,
  settings: PropTypes.object,
  alt: PropTypes.string,
  screenSize: PropTypes.string,
  className: PropTypes.string,
}

SlideMedia.defaultProps = {
  type: "",
  screenSize: "",
  settings: {},
  alt: "",
}

const Slide = ({ children, className, ...props }) => {
    return (
        <li className={"glide__slide " + className} {...props}>
          {children}
        </li>
    );
}

Slide.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Slide.defaultProps = {
  className: "",
}

class Slider extends React.Component {
    constructor(props) {
        super(props);
       this.videoActiveAutoPlay = this.videoActiveAutoPlay.bind(this);
    }

    componentDidMount() {
        const { id, settings } = this.props;

        this.glide = new Glide(`.glide_${id}`, { ...settings });
        this.glide.mount();
        this.glide.on(`move.after`, () => {
            this.videoActiveAutoPlay();
        });
        this.glide.on(`resize`, () => {
            this.videoActiveAutoPlay();
        });
        this.videoActiveAutoPlay();
    }

    componentWillUnmount() {
        this.glide.destroy()
    }

    videoActiveAutoPlay = () => {
      const { id } = this.props;
      const glideSlider = document.getElementById(`glide_${id}`);
      const hideSize = (window.innerWidth >= 680 ? 'mobile' : 'desktop');
      
      const allVideosAutoplay = glideSlider.querySelectorAll('.glide__slide:not(.glide__slide--active) video, .glide__slide.glide__slide--active video.' + hideSize + '');
      for (let x = 0; x < allVideosAutoplay.length; x++) {
        allVideosAutoplay[x].pause(); 
      }

      const video = glideSlider.querySelector('.glide__slide.glide__slide--active video:not(.' + hideSize + ')[autoplay]');
      if(video){
        video.play();
      }
    }

    render() {

        const { id, className, children } = this.props;
        if(id == ''){ return ''; }
        if(!children){ return ''; }
        return (
            <section className={ "block_section__slider_custom " + (className || '') }>
                <div className="block__slider">
                    <div id={`glide_${id}`} className={` glide glide_${id}`}>
                        <div className="glide__track" data-glide-el="track">
                            <ul className="glide__slides">
                                {children}
                            </ul>
                        </div>
                        <div data-glide-el="controls">
                            <button className="controls__button controls__button-left glide__arrow--left" data-glide-dir="<" />
                            <button className="controls__button controls__button-right glide__arrow--right" data-glide-dir=">" />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Slider.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    settings: PropTypes.object,
}

Slider.defaultProps = {
  id: "custom_slider",
  className: "",
  settings: {},
}

export {
  Slider,
  Slide,
  SlideMedia,
  SlideContent,
};


/*
<Slider id="mon-test-slider-1" className="" >
  <Slide className="" >
    <SlideContent>
      <h2>MON TEST SLIDER CUSTOM WITH VIDEO</h2>
    </SlideContent>
    <SlideMedia 
      src="https://ardene-craftcms.stage.okam.io/assets/video/ardenexmetowe.mp4" 
      type="video/mp4" 
      className="" 
      settings={{ autoPlay: true, controls: false, loop: true, muted: true }} 
      alt="" 
      screenSize="desktop" 
    ></SlideMedia>
    <SlideMedia 
      src="https://ardene-craftcms.stage.okam.io/assets/video/honey.mp4" 
      type="video/mp4" 
      className="" 
      settings={{ autoPlay: true, controls: false, loop: true, muted: true }} 
      alt="" 
      screenSize="mobile" 
    ></SlideMedia>
  </Slide>
  
   <Slide className="" >
    <SlideContent>
      <h2>MON TEST SLIDER CUSTOM WITH VIDEO</h2>
    </SlideContent>
    <SlideMedia 
      src="https://acc.ardenecorporate.com/assets/slider/What-We-Learned-DesktopSlider.jpg" 
      type="image/jpeg" 
      className="" 
      alt="" 
      screenSize="desktop" 
    ></SlideMedia>
    <SlideMedia 
      src="https://acc.ardenecorporate.com/assets/slider/Mobile-Slider.jpg" 
      type="image/jpeg"
      className="" 
      alt="" 
      screenSize="mobile" 
    ></SlideMedia>
  </Slide>

  <Slide className="" >
    <SlideMedia 
      src="https://ardene-craftcms.stage.okam.io/assets/video/ardenexmetowe.mp4" 
      type="video/mp4" 
      className="" 
      settings={{ autoPlay: true, controls: false, loop: true, muted: true }} 
      alt="" 
      screenSize="desktop" 
    ></SlideMedia>
    <SlideMedia 
      src="https://ardene-craftcms.stage.okam.io/assets/video/honey.mp4" 
      type="video/mp4" 
      className="" 
      settings={{ autoPlay: true, controls: false, loop: true, muted: true }} 
      alt="" 
      screenSize="mobile" 
    ></SlideMedia>
  </Slide>

  <Slide>
    <SlideMedia 
      src="https://ardene-craftcms.stage.okam.io/assets/video/ardenexmetowe.mp4" 
      type="video/mp4" 
      settings={{ autoPlay: true, controls: false, loop: true, muted: true }} 
    ></SlideMedia>
  </Slide>

</Slider>
*/
