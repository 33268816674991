import React from "react";
import PropTypes from "prop-types";
// import Parser from "html-react-parser";
// import { Helmet } from "react-helmet"

const SingleRessourceAsset = ({ url, kind }) => {
  if(url && kind){
    switch(kind){
      case "javascript": 
        return (<script async defer src={url} type="text/javascript"></script>);

      case "stylesheet": 
        return (<link href={url} type="text/css" rel={kind} />);
  
    }
  }
  return null;
}
SingleRessourceAsset.propTypes = {
  url: PropTypes.string,
  kind: PropTypes.string,
}

const RessourceAssets = ({ ressourceAssets }) => (
	<>
      {ressourceAssets && ressourceAssets.map((asset, index) => (
          (asset.kind && asset.url && 
            <SingleRessourceAsset 
              key={index} 
              url={asset.url} 
              kind={asset.kind} 
              {...asset}
            />
          )
      ))}
	</>
)
RessourceAssets.propTypes = {
  ressourceAssets: PropTypes.array.isRequired,
}
RessourceAssets.defaultProps = {
  ressourceAssets: [],
}

export default RessourceAssets;
