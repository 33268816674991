import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const ImageHero = ({ img, imgMob }) => {
  return (
    <section>
      <Img fluid={img.childImageSharp.fluid} className="img_header desktop" />
      <Img fluid={imgMob.childImageSharp.fluid} className="img_header mobile" />
    </section>
  )
}

ImageHero.propTypes = {
  img: PropTypes.object.isRequired,
  imgMob: PropTypes.object.isRequired,
}
  
export default ImageHero
