import React from "react"
import PropTypes from "prop-types"
import RessourceAssets from "./ressourceAssets"
import Header from "./header"
import RenderMarkdown from "../core/renderMarkdown"
import "../styles/tailwind.css"
import withLocation from "./withLocation"

const Layout = ({ header, footer, children, mainMenu, params, ressourceAssets }) => {
  const { app } = params;
  return (
    <>
      <RessourceAssets ressourceAssets={ressourceAssets} />
      {!app && <Header header={header} mainMenu={mainMenu} />}
        <main>{children}</main>
      {!app && <RenderMarkdown md={footer} />}
    </>
  )
}

Layout.propTypes = {
  params: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  footer: PropTypes.string.isRequired,
  mainMenu: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  globProps: PropTypes.array,
  ressourceAssets: PropTypes.array,
}

export default withLocation(Layout)