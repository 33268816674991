import React, { useContext } from "react"
import { Link } from "gatsby"
import { AlternateLinksContext } from "./wrapWithI18nProvider"
import { useTranslation } from "react-i18next"

const LanguageSwitcher = () => {
  const alternateLinks = useContext(AlternateLinksContext)
  const { i18n } = useTranslation()

  const getTranslatedPath = () => {
    const link = alternateLinks ? alternateLinks.filter(link => link.language !== i18n.language) : []
    const fallback = i18n.language === 'en' ? '/fr' : '/'
    return link.length ? link[0].path : fallback
  }

  return (
    <Link to={getTranslatedPath()}>
      {i18n.language === 'en' ? 'FR' : 'EN'}
    </Link>
  )
}

export default LanguageSwitcher
