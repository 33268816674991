import MDX from "@mdx-js/runtime"
import React from "react"
import PropTypes from "prop-types"

import CatchError from "./catchError"
import { CMS_COMPONENTS, CMS_SHORTCODES } from "../cms/CMSComponents"
import { sanitizeMarkdown } from "../cms/CMSUtils"

/* Use this component to parse markdown using MDX. See MDX runtime for details.
 * The map provided to the components prop instructs MDX on how to render your HTML and
 * custom React components.
 *
 * @md: string - Markdown to be parsed
 */

const RenderMarkdown = ({ md, ...props }) => (
  <CatchError>
    <MDX
      components={{...CMS_COMPONENTS, ...CMS_SHORTCODES}}
      {...props}
    >
      {sanitizeMarkdown(md)}
    </MDX>
  </CatchError>
)

RenderMarkdown.propTypes = {
  md: PropTypes.string.isRequired
}

export default RenderMarkdown