import React from "react"

const IconFacebook = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 88 88"
      style={{enableBackground: `new 0 0 88 88`}}
      xmlSpace="preserve"
      className="icon icon_social icon__facebook"
    >
      <path
        d="M38.6,45.1v20.4h8.4V45.1h8.4v-8.4h-8.4v-4.3c0-0.6,0.2-1.3,0.5-1.8c0.3-0.4,0.7-0.7,1.2-0.7h6.7v-8.4h-6.7c-2.7,0-5.4,1.2-7.2,3.2c-2,2.1-3.1,4.9-3,7.8v4.2h-6.8v8.4L38.6,45.1L38.6,45.1z"
      />
    </svg>
  )
}

export default IconFacebook
