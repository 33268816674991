import React from "react"

const IconTwitter = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 88 88"
      style={{enableBackground: `new 0 0 88 88`}}
      xmlSpace="preserve"
      className="icon icon_social icon__twitter"
    >
      <path
        d="M37.3,61.7c2.9,0,5.8-0.4,8.5-1.4c2.5-0.9,4.7-2.2,6.7-3.9c1.9-1.6,3.5-3.5,4.9-5.5
          c1.3-2,2.3-4.2,3-6.5c0.6-2.2,1-4.5,1-6.8v-1.1c1.7-1.2,3.1-2.7,4.2-4.4c-1.5,0.7-3.2,1.1-4.8,1.3c1.8-1,3.1-2.7,3.7-4.7
          c-1.7,1-3.5,1.7-5.4,2.1c-3.2-3.4-8.6-3.6-12-0.4c-1.7,1.6-2.7,3.8-2.7,6.2c0,0.7,0.1,1.3,0.2,1.9c-3.4-0.2-6.7-1.1-9.7-2.6
          c-3-1.5-5.6-3.6-7.7-6.2c-0.8,1.3-1.2,2.8-1.2,4.3c0,1.4,0.4,2.8,1,4c0.6,1.2,1.6,2.3,2.7,3c-1.4,0-2.7-0.4-3.9-1V40
          c0,2,0.7,3.9,1.9,5.4c1.2,1.5,2.9,2.6,4.9,2.9c-0.7,0.2-1.5,0.3-2.2,0.3c-0.5,0-1.1,0-1.6-0.1c0.5,1.7,1.6,3.1,3,4.2
          c1.4,1.1,3.1,1.7,4.9,1.7c-3,2.4-6.7,3.7-10.5,3.6c-0.7,0-1.3,0-2-0.1C28.2,60.4,32.7,61.7,37.3,61.7L37.3,61.7z"
      />
    </svg>
  )
}

export default IconTwitter
