import React from "react"
import PropTypes from "prop-types"


const MainMenuItemLink = ( props ) => {
    const { 
        title, 
        nodeUri, 
        classes,
        post_accentColor,
        type,
        level,
    } = props;

    const className = `text-3xl lg:text-xs ardene-nav-item leading-tight block ${level === 0 ? 'nav-btn ' : ''} item_lvl-${level} ${(post_accentColor || '')} ${(classes || '')}`;
    const href = (type === "craft\\elements\\Entry" ? '/': '') + nodeUri;

    return (
        <>
            <a href={href} className={className} >{title}</a>
        </>
    );
}

MainMenuItemLink.propTypes = {
    level: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    nodeUri: PropTypes.string.isRequired,
    classes: PropTypes.string,
    type: PropTypes.string,
    post_accentColor: PropTypes.string,
}


const MainMenuItemContainer = ( props ) => {
    const { 
        title, 
        children, 
        openSub, 
        classes,
        post_accentColor,
    } = props;

    return (
        <>
            <button onClick={openSub} className={`ardene-nav-item nav-btn text-3xl lg:text-xs cursor-pointer ${(post_accentColor || '')} ${(classes || '')}`} >{title}</button>
            <ul className="submenu lg:absolute mt-4 hidden lg:block z-10">
                {children.map((item, i) => (
                    <li className="mb-1 menu_item menu_sub_item text-3xl lg:text-xs" key={i} >
                        <MainMenuItemLink {...item} level={1} />
                    </li>
                ))}
            </ul>
        </>
    );
}

MainMenuItemContainer.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
    openSub: PropTypes.func.isRequired,
    classes: PropTypes.string,
    post_accentColor: PropTypes.string,
}


const MainMenu = ({mainMenu, openSub }) => {

    return (
        <>
            <ul className="flex flex-col lg:flex-row lg:w-3/4 w-full justify-center items-center lg:items-stretch mt-8 lg:mt-0">
                {mainMenu.map((item, i) => (
                    <li className="m-2 lg:my-8 menu_item text-xs w-full" key={i} >
                        {item.children && item.nodeUri == "" ?
                            <MainMenuItemContainer {...item} openSub={openSub} />
                        :
                            <MainMenuItemLink {...item} level={0} />
                        }
                    </li>
                    
                ))}
            </ul>
        </>
    );
  
}

MainMenu.propTypes = {
  mainMenu: PropTypes.array.isRequired,
  openSub: PropTypes.func.isRequired,
}

export default MainMenu