import React from "react"
import { useTranslation } from "react-i18next"

const IconBlog = () => {
  const { i18n } = useTranslation()

  return (
    <span>
      <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 88 88"
        style={{enableBackground: `new 0 0 88 88`}}
        xmlSpace="preserve"
        className="icon icon_social icon__blog"
      >
        {i18n.language === 'en' ?
          <path
            className="lang__en" 
            d="M38.4,20.9l-2.2,7.9c1.5-1.3,3.6-1.7,5.6-1.1c3.4,0.9,5.8,4.3,4.4,9.4c-1.4,5.1-5.2,6.9-8.7,6c-1.9-0.4-3.5-1.8-4.2-3.6l-0.5,2l-4.8-1.3l5.6-20.4L38.4,20.9z M38.8,31.8c-1.7-0.4-3.6,0.3-4.1,2.2s0.7,3.5,2.4,3.9s3.6-0.3,4.1-2.2S40.5,32.3,38.8,31.8z M60.2,22.4l-5.6,20.5l-4.8-1.3l5.6-20.5L60.2,22.4z M34.9,46.6c4.6,1.2,7.4,5.1,6.1,9.8c-1.3,4.7-5.6,6.7-10.3,5.5c-4.6-1.2-7.4-5.1-6.1-9.8C26,47.5,30.3,45.4,34.9,46.6z M33.7,51.2c-1.7-0.4-3.6,0.2-4.1,2.2s0.7,3.5,2.4,3.9c1.7,0.4,3.6-0.3,4.1-2.2S35.4,51.7,33.7,51.2z M54.3,46.4c1.9,0.4,3.4,1.8,4,3.7l0.5-2.1l4.8,1.3l-3.7,13.6c-1.1,4-4.1,6.8-10.7,5c-2.3-0.5-4.4-1.6-6.1-3.2l3.1-3.7c1.1,1.1,2.4,1.9,3.9,2.3c3.1,0.8,4.6-0.1,5.2-2l0.2-0.8c-1.4,1.1-3.4,1.5-5.2,0.9c-3.7-0.9-6.2-4.2-4.8-9.2C46.9,47.2,50.8,45.5,54.3,46.4L54.3,46.4z M54.5,51.4c-1.7-0.4-3.6,0.2-4.1,2.2s0.7,3.5,2.4,3.9s3.6-0.3,4.1-2.2S56.2,51.8,54.5,51.4z"
          />
        :
          <path
            className="lang__fr" 
            d="M33.3,21.8l-2.1,7.6c1.5-1.2,3.5-1.6,5.3-1.1c3.2,0.8,5.5,4.1,4.2,9c-1.3,4.9-5,6.6-8.3,5.8c-1.8-0.4-3.3-1.7-4-3.5L28,41.6l-4.6-1.3l5.4-19.7L33.3,21.8z M33.7,32.4c-1.6-0.4-3.4,0.2-3.9,2.1s0.6,3.3,2.3,3.7s3.4-0.2,3.9-2.1S35.4,32.8,33.7,32.4L33.7,32.4z M54.1,23.3l-5.4,19.7l-4.6-1.2L49.5,22L54.1,23.3z M65,28c4.4,1.1,7,4.9,5.8,9.4c-1.2,4.5-5.4,6.5-9.8,5.3c-4.4-1.1-7-4.9-5.8-9.4C56.4,28.8,60.5,26.9,65,28L65,28z M63.8,32.5c-1.6-0.4-3.4,0.2-3.9,2.1s0.6,3.3,2.3,3.8c1.6,0.4,3.4-0.2,3.9-2.1S65.4,32.9,63.8,32.5L63.8,32.5z M25.5,46.3c1.8,0.4,3.3,1.7,3.9,3.5l0.5-2l4.6,1.2l-3.5,13c-1,3.8-4,6.5-10.2,4.8c-2.2-0.5-4.2-1.5-5.9-3l2.9-3.5c1,1,2.3,1.8,3.8,2.2c2.9,0.7,4.4-0.1,4.9-1.9l0.2-0.7c-1.4,1.1-3.2,1.4-5,0.9c-3.5-0.9-5.9-4-4.6-8.8C18.5,47.1,22.2,45.4,25.5,46.3z M25.7,51.1c-1.6-0.4-3.4,0.2-3.9,2.1s0.6,3.3,2.3,3.8s3.4-0.3,3.9-2.1C28.6,53,27.4,51.5,25.7,51.1z M52.5,48.7l-3.8,14.1l-4.4-1.2l0.6-2.2c-1.4,1.3-3.3,1.8-5.2,1.3c-2.9-0.7-4.2-3.2-3.2-6.6l2.4-9l4.7,1.2l-2.1,7.6c-0.5,1.8,0.1,2.7,1.2,3c1.4,0.4,2.8-0.4,3.4-2.5l1.9-6.9L52.5,48.7z M64.5,46.6c4.3,1.1,6.4,4.7,5.1,9.4l-0.3,1.2l-10-2.6l0,0.1c0.3,1.4,1.7,2.3,2.8,2.6c1.3,0.3,2.7,0.3,4.1,0l0.7,4.2c-1.4,0.5-3.3,0.7-6.1,0c-5.2-1.3-7-5.5-5.9-9.5C56.1,47.7,59.8,45.4,64.5,46.6z M63.2,50.3c-1.3-0.3-2.4-0.1-3.2,1.1l5.1,1.3C65.3,51.6,64.6,50.6,63.2,50.3z"
          />
        }
      </svg>
    </span>
  )
}

export default IconBlog