import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const ImageDesktop = ({ img, imgMob, className }) => {
  return (
    <div className={className}>
      <Img fluid={img.childImageSharp.fluid} className="desktop" />
      <Img fluid={imgMob.childImageSharp.fluid} className="mobile" />
    </div>
  )
}

ImageDesktop.defaultProps = {
  className: ``,
}

ImageDesktop.propTypes = {
  img: PropTypes.object.isRequired,
  imgMob: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default ImageDesktop