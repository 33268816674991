import React from "react"

const IconSnapchat = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 88 88"
      style={{enableBackground: `new 0 0 88 88`}}
      xmlSpace="preserve"
      className="icon icon_social icon__snapchat"
    >
      <path
        d="M66.6,55.1c-6.8-1.1-9.9-7.9-10-8.2l0,0c-0.4-0.7-0.5-1.5-0.2-2.2c0.5-1.1,2-1.6,3-1.9c0.2-0.1,0.5-0.1,0.7-0.2c1.7-0.7,2.1-1.3,2.1-1.8c-0.1-0.6-0.5-1-1.1-1.1h0c-0.3-0.1-0.5-0.1-0.8-0.1c-0.2,0-0.5,0-0.7,0.1c-0.7,0.4-1.5,0.6-2.3,0.6l0,0c-0.5,0-1-0.2-1.4-0.5l0,0v0c0-0.4,0.1-0.9,0.1-1.4v0c0.2-3.1,0.5-7-0.6-9.2c-2-4.4-6.5-7.2-11.4-7.2h-0.1L43,22c-4.9-0.1-9.3,2.7-11.4,7.1c-1,2.2-0.8,6.1-0.6,9.2c0,0.5,0.1,1,0.1,1.4v0l0,0c-0.4,0.4-1,0.5-1.5,0.5c-0.9,0-1.7-0.2-2.5-0.6c-0.2-0.1-0.3-0.1-0.5-0.1c-0.8,0-1.5,0.4-1.8,1.1c-0.1,0.4,0.1,1.1,2,1.9c0.2,0.1,0.4,0.1,0.7,0.2c1,0.3,2.6,0.8,3,1.9c0.3,0.7,0.2,1.5-0.2,2.2l0,0c-0.1,0.3-3.2,7.1-10,8.2L20,55.2l0,0.4c0,0.1,0,0.2,0.1,0.2C20.6,57,24,57.7,26,58c0.4,0.1,0.6,0.6,0.8,1.5v0c0.1,0.3,0.2,0.7,0.3,1c0.1,0.3,0.3,0.5,0.6,0.4c0.3,0,0.7-0.1,1-0.1c0.9-0.2,1.8-0.3,2.7-0.3c0.6,0,1.3,0.1,1.9,0.2c1.4,0.3,2.6,1,3.7,1.8c1.7,1.2,3.5,2.4,6.3,2.4c0.1,0,0.2,0,0.2,0h0c0.1,0,0.2,0,0.4,0c2.8,0,4.5-1.2,6.3-2.4c1.1-0.9,2.3-1.5,3.7-1.8c0.6-0.1,1.3-0.2,1.9-0.2c0.9,0,1.8,0.1,2.7,0.3c0.3,0.1,0.6,0.1,1,0.1h0.1c0.3,0,0.5-0.1,0.6-0.4c0.1-0.4,0.2-0.7,0.3-1.1c0.2-0.9,0.4-1.5,0.8-1.5c4.4-0.7,5.6-1.5,5.9-2.2c0-0.1,0.1-0.2,0.1-0.2l0-0.4L66.6,55.1z"
      />
    </svg>
  )
}

export default IconSnapchat
