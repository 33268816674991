import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Link } from "gatsby"

import Logo from "./logo"
import RenderMarkdown from "../core/renderMarkdown"
import MainMenu from "./mainMenu"

class Header extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      openNav: false
    }

    this.openSub = this.openSub.bind(this);
  }

  componentDidMount () {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    this.setState({ openNav: window.innerWidth >= 1024 ? true : false })
  }

  navOpen = event => {
    event.preventDefault()
    this.setState(prevState => ({ openNav: !prevState.openNav }))
  }

  openSub (event) {
    event.preventDefault()
    const subOpenClass = [...document.getElementsByClassName('submenu')]
    const nextSibling = event.target.nextElementSibling

    subOpenClass.forEach(element => {
      element.classList.remove('submenuOpen')
    })

    nextSibling.classList.add('submenuOpen')
  }

  render () {
    const { i18n, header, mainMenu } = this.props
    const { openNav } = this.state

    const homepath = i18n.language !== 'en' ? `/${i18n.language}/` : `/`;

    return (
      <nav className="ardene-nav flex flex-col items-center lg:items-start text-center lg:text-left lg:flex-row justify-center py-4 navbar sticky top-0 z-50 bg-white">
        <div className="w-32 left-0 z-30 lg:w-1/4 w-full flex items-center">
          <Link to={homepath} className="block w-32 ml-8 mt-4 lg:mt-6">
            <Logo className="w-full" />
          </Link>
        </div>

        {openNav &&
          <div className="principal-menu-container lg:initial lg:mx-8 z-10 lg:block min-h-screen h-auto lg:min-h-0 lg:h-auto">
            <div className="relative flex flex-col lg:flex-row mt-4 w-full h-auto justify-between lg:mt-0">
              <MainMenu  openSub={this.openSub} mainMenu={mainMenu || []} />
              <RenderMarkdown  md={header} />
            </div>
          </div>
        }
        <div className="menu-btn menuclose lg:hidden absolute right-0 mr-8 z-30">
          <button onClick={this.navOpen} className="cursor-pointer">
            {openNav
              ? <div className="w-8">
                  <img src="/images/Menu_Close.png" className="close-icon" alt="" />
                </div>
              : <div>
                  <div className="openMenuLine" />
                  <div className="openMenuLine" />
                  <div className="openMenuLine" />
                </div>
            }
          </button>
        </div>
        <div className="submenu-background z-0 lg:h-48 left-0 right-0" />
      </nav>
    )
  }
}

Header.propTypes = {
  i18n: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  mainMenu: PropTypes.array.isRequired,
}

export default withTranslation()(Header)