/* eslint-disable react/display-name */
import React from "react"
import { Link } from "gatsby"

import LanguageSwitcher from "../components/languageSwitcher"
import SmartLink from "../components/smartLink"
import ImageHero from "../components/images/imageHero"
import ImageDesktop from "../components/images/imageDesktop"
import ImageTablet from "../components/images/imageTablet"
import IconFacebook from "../components/icons/iconFacebook"
import IconInstagram from "../components/icons/iconInstagram"
import IconTwitter from "../components/icons/iconTwitter"
import IconSnapchat from "../components/icons/iconSnapchat"
import IconYoutube from "../components/icons/iconYoutube"
import IconSpotify from "../components/icons/iconSpotify"
import IconPinterest from "../components/icons/iconPinterest"
import IconTiktok from "../components/icons/iconTiktok"
import IconBlog from "../components/icons/iconBlog"
import { Slider, Slide, SlideMedia, SlideContent } from "../components/media/customSlider"

// Include all components that will be parsed by MDX as React components here.
// Any React component you'd like to allow your editors to use should be placed here.
export const CMS_SHORTCODES = {
  Link: props => <Link {...props} />,
  LanguageSwitcher: props => <LanguageSwitcher {...props} />,
  ImageHero: props => <ImageHero {...props} />,
  ImageDesktop: props => <ImageDesktop {...props} />,
  ImageTablet: props => <ImageTablet {...props} />,
  IconFacebook: props => <IconFacebook {...props} />,
  IconInstagram: props => <IconInstagram {...props} />,
  IconTwitter: props => <IconTwitter {...props} />,
  IconSnapchat: props => <IconSnapchat {...props} />,
  IconYoutube: props => <IconYoutube {...props} />,
  IconSpotify: props => <IconSpotify {...props} />,
  IconPinterest: props => <IconPinterest {...props} />,
  IconTiktok: props => <IconTiktok {...props} />,
  IconBlog: props => <IconBlog {...props} />,
  Slider: props => <Slider {...props} />,
  Slide: props => <Slide {...props} />,
  SlideMedia: props => <SlideMedia {...props} />,
  SlideContent: props => <SlideContent {...props} />
}

// Include any tags you'd like to replace with React components
export const CMS_COMPONENTS = {
  a: props => <SmartLink {...props} />
}
