import React from "react"

const IconSpotify = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 88 88"
      style={{enableBackground: `new 0 0 88 88`}}
      xmlSpace="preserve"
      className="icon icon_social icon__spotify"
    >
      <path
        d="M63.8,46.7C52.9,40,37.1,38.2,24.3,42c-1.4,0.4-2.3,2-1.8,3.4c0.4,1.4,2,2.3,3.4,1.8
          c11.2-3.4,25.6-1.7,35,4.1c1.3,0.8,3,0.4,3.8-0.8C65.5,49.2,65.1,47.5,63.8,46.7C63.8,46.7,63.8,46.7,63.8,46.7 M68.4,34.9
          c-13-7.7-33.7-8.4-46.1-4.7c-1.7,0.5-2.7,2.4-2.2,4.1c0.5,1.7,2.4,2.7,4.1,2.2c10.8-3.3,29.5-2.7,40.8,4c1.6,0.9,3.6,0.4,4.5-1.1
          S70,35.8,68.4,34.9 M59.7,56.9C50.4,51.3,38.9,50,25.5,53c-1.2,0.3-1.9,1.4-1.6,2.6c0.3,1.2,1.4,1.9,2.6,1.6
          c12.2-2.8,22.6-1.6,30.9,3.4c1,0.7,2.4,0.4,3-0.7C61,59,60.7,57.6,59.7,56.9C59.7,57,59.7,57,59.7,56.9"
      />
    </svg>
  )
}

export default IconSpotify
