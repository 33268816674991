import React from "react"

const IconPinterest = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 88 88"
      style={{enableBackground: `new 0 0 88 88`}}
      xmlSpace="preserve"
      className="icon icon_social icon__pinterest"
    >
      <path
        d="M29.7,44.7c0.8,1.6,2.1,2.9,3.8,3.5c0.2,0.1,0.5,0.1,0.8,0c0.2-0.1,0.4-0.3,0.4-0.6
          c0.1-0.3,0.2-0.6,0.3-1c0.1-0.3,0.2-0.7,0.3-1c0.1-0.5,0-1-0.3-1.4c-1.1-1.4-1.7-3.1-1.6-4.8c0-3.1,1.2-6.1,3.4-8.3
          c2.3-2.3,5.4-3.6,8.7-3.5c3.2,0,5.7,0.8,7.5,2.6c1.8,1.8,2.8,4.2,2.7,6.8c0.1,3.2-0.6,6.4-2.2,9.2c-1.5,2.6-3.3,3.8-5.6,3.8
          c-1.2,0-2.3-0.5-3.1-1.4c-0.8-0.9-1.1-2.2-0.8-3.3c0.2-1,0.5-2,0.9-3c0.4-1.2,0.8-2.3,1-3.3c0.2-0.8,0.3-1.6,0.3-2.4
          c0.1-1-0.2-1.9-0.9-2.6c-0.6-0.7-1.5-1.1-2.5-1c-1.3,0-2.6,0.7-3.3,1.8c-1,1.3-1.5,2.9-1.4,4.6l0,0.6c0,0.5,0.1,1,0.2,1.5
          c0.1,0.6,0.3,1.1,0.6,1.7l-3.2,13.3c-0.1,0.6-0.2,1.3-0.3,2c-0.1,0.7-0.1,1.4-0.1,2c0,0.6,0,1.2,0,1.9s0.1,1.2,0.1,1.6
          c0.1,0.4,0.1,0.8,0.1,1.2l0,0.6c0,0.1,0.1,0.2,0.3,0.3c0.1,0,0.3,0,0.3-0.1l0.3-0.3c0.2-0.2,0.4-0.5,0.6-0.7c0.3-0.3,0.5-0.7,0.7-1
          c0.3-0.4,0.5-0.9,0.8-1.3c0.3-0.5,0.6-0.9,0.8-1.4c0.3-0.5,0.5-1.1,0.7-1.6c0.2-0.5,0.4-1.1,0.6-1.6l1.7-6.7
          c0.6,0.9,1.4,1.7,2.4,2.2c1.1,0.6,2.3,0.9,3.6,0.9c3.8,0,7.3-1.7,9.5-4.8c2.4-3.2,3.7-7.2,3.7-12c0-3.7-1.6-7.3-4.3-9.8
          c-3-2.8-7-4.3-11.1-4.2c-2.6,0-5.2,0.4-7.6,1.4c-2.1,0.8-4,2.1-5.5,3.7c-1.3,1.4-2.4,3.1-3.2,4.9C28,37.1,28,41.2,29.7,44.7z"
      />
    </svg>
  )
}

export default IconPinterest
