import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const ImageTablet = ({ img, imgMob, className }) => {
  return (
    <div className={className}>
      <Img fluid={img.childImageSharp.fluid} className="tablet" />
      <Img fluid={imgMob.childImageSharp.fluid} className="mobile-large" />
    </div>
  )
}

ImageTablet.defaultProps = {
  className: ``,
}

ImageTablet.propTypes = {
  img: PropTypes.object.isRequired,
  imgMob: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default ImageTablet
