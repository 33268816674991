import React from "react"

const IconYoutube = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 88 88"
      style={{enableBackground: `new 0 0 88 88`}}
      xmlSpace="preserve"
      className="icon icon_social icon__youtube"
    >
      <path
        d="M53.1,43.8c0,0.6-0.3,1.1-0.8,1.3l-12.8,8c-0.3,0.2-0.5,0.3-0.8,0.2c-0.3,0-0.5-0.1-0.8-0.2
          c-0.5-0.3-0.8-0.8-0.8-1.4v-16c0-0.6,0.3-1.1,0.8-1.4c0.5-0.3,1.1-0.3,1.6,0l12.8,8C52.8,42.7,53.1,43.2,53.1,43.8z M65.9,43.8
          c0.1-3.6-0.1-7.3-0.8-10.8c-0.5-2.4-2.4-4.2-4.8-4.5c-5.6-0.5-11.2-0.7-16.8-0.6c-5.6-0.1-11.2,0.1-16.8,0.6
          c-2.4,0.3-4.3,2.1-4.8,4.5c-0.7,3.6-0.9,7.2-0.8,10.8c-0.1,3.6,0.1,7.3,0.8,10.8c0.5,2.4,2.4,4.2,4.8,4.5c5.6,0.5,11.2,0.7,16.8,0.6
          c5.6,0.1,11.2-0.1,16.8-0.6c2.4-0.3,4.4-2.1,4.8-4.5C65.8,51,66,47.4,65.9,43.8z"
      />
    </svg>
  )
}

export default IconYoutube
