export const parseMetadata = seomatic => {
  const { metaTitleContainer, metaTagContainer } = seomatic

  return {
    title: JSON.parse(metaTitleContainer).title.title,
    description: JSON.parse(metaTagContainer).description.content,
    image: JSON.parse(metaTagContainer)[`og:image`].content
  }
}


// fix <br> Tag
const fixBreakTag = md => {
  return md.replace(/<br>/g,'<br/>');
}
// fix <hr> Tag
const fixLineTag = md => {
  return md.replace(/<hr>/g,'<hr/>');
}

const fixHTMLTags = md => {
  let _md = md;
  _md = fixBreakTag(_md);
  _md = fixLineTag(_md);
  return _md;
}

// remove space(s) bretween bracket & when bracket only containe space(s)
const removeContentIfOnlySpaces = md => {
  return md.replace(/>\s+</g,'><');
}

// transform attribute "class" to "className" (to prevent error)
const fixClassAttributes = md => {
  return md.replace(/\sclass=/g,' className=');
}

export const sanitizeMarkdown = md => {
  let result = (md || '');
  result = fixHTMLTags(result);
  result = fixClassAttributes(result);
  result = removeContentIfOnlySpaces(result);
  return result;
}