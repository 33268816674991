import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Logo = (className) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "Ardene-Black-Logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return <Img fluid={data.logo.childImageSharp.fluid} className={className} />
}

Logo.defaultProps = {
  className: ``,
}

Logo.propTypes = {
  className: PropTypes.string,
}

export default Logo
