import React from "react"

const IconTiktok = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 88 88"
      style={{enableBackground: `new 0 0 88 88`}}
      xmlSpace="preserve"
      className="icon icon_social icon__tiktok"
    >
      <path
        d="M40.5,36.1c0,2.8,0,5.2,0,7.8c-0.7,0-1.4,0-2.1,0c-4.6,0.1-7.7,2.9-7.7,7.1c0,3.9,3.2,7,7.1,7c4.2,0,7-3.1,7-7.8c0-8.9,0-17.8,0-26.8c0-0.7,0-1.3,0-2.2c2.5,0,4.8,0,7.1,0c0.3,0,0.8,0.3,1,0.6c4.9,6.4,4.9,6.4,11.5,8.8c-0.2,1.2-0.5,2.4-0.7,3.6c-0.2,1.2-0.5,2.3-0.7,3.4c-3.3,0-6.2-1.2-10.3-4.4c0,0.6-0.1,1-0.1,1.5c0,5.4,0,10.7,0,16.1c-0.1,7.9-6.2,14.4-14,14.8c-7.8,0.4-14.6-5.2-15.6-12.9c-1-7.7,4.2-15,12-16.4C36.8,36.1,38.6,36.2,40.5,36.1z"
      />
    </svg>
  )
}

export default IconTiktok
